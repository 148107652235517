import "../../App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { useNavigate } from "react-router-dom";
import { ChargerTable } from "../../components/pro_calculator/tables/ChargerTable";

function ChargingStations() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const [error, setError] = useState(null);

  const [chargerOwner, setChargerOwner] = useState("");
  const [chargerAddress, setChargerAddress] = useState("");
  const [chargerNumbers, setChargerNumbers] = useState("");
  const [chargerFee, setChargerFee] = useState("");

  const authToken = localStorage.getItem("authToken");

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  const data = {
    owner: chargerOwner,
    address: chargerAddress,
    chargers: chargerNumbers,
    fee: chargerFee,
  };

  const addCharger = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/addcharger/",
        data,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      setError("");
      console.log("response", response);
    } catch (error) {
      setError("Error adding charger");
      return;
    }
  };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col">
        <div className="map-dashboard-container">
          <h1> Charging Stations </h1>
          <CustomMap />
        </div>
        <div className="dashboard-container">
          <ChargerTable />
        </div>
      </div>
      {/* <div className="dashboard-container col-md-6 col-md-offset-3">
        <div className="form-container">
          <h3>Add a charging station</h3>
          <form onSubmit={addCharger}>
            <div className="form-group">
              <label htmlFor="inputChargerOwner">Owner of Charger</label>
              <input
                type="text"
                className="form-control"
                value={chargerOwner}
                onChange={(e) => setChargerOwner(e.target.value)}
                required
              ></input>
              <label htmlFor="inputChargerOwner">Address</label>
              <input
                type="text"
                className="form-control"
                value={chargerAddress}
                onChange={(e) => setChargerAddress(e.target.value)}
                required
              ></input>
              <label htmlFor="inputChargerOwner">Number of chargers</label>
              <input
                type="text"
                className="form-control"
                value={chargerNumbers}
                onChange={(e) => setChargerNumbers(e.target.value)}
                required
              ></input>
              <label htmlFor="inputChargerOwner">Charging fee</label>
              <input
                type="number"
                className="form-control"
                value={chargerFee}
                onChange={(e) => setChargerFee(e.target.value)}
                required
              ></input>
            </div>
            <button className="btn btn-default">Add charger</button>
          </form>
          {error && <p className="text-danger">{error}</p>}
        </div>
      </div> */}
    </div>
  );
}

export default ChargingStations;
