import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./CustomMap.css";
import "leaflet-routing-machine";

import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";

const RoutingControl = ({ routeCheckbox }) => {
  const map = useMap();

  const defaultIcon = L.icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
    shadowUrl: "https://unpkg.com/leaflet@1.9.1/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    let routingControl;

    if (routeCheckbox) {
      routingControl = L.Routing.control({
        waypoints: [L.latLng(33.9, -117.5), L.latLng(34.0, -118.2)],
        routeWhileDragging: true,
        showAlternatives: true,
        altLineOptions: {
          styles: [{ color: "gray", opacity: 0.6, weight: 4 }],
        },
        createMarker: (i, waypoint, n) => {
          if (i === 0) {
            // start point
            const circleMarker = L.circleMarker(waypoint.latLng, {
              radius: 7,
              color: "black",
              fillColor: "white",
              fillOpacity: 0.8,
              zIndexOffset: 1000,
            }).addTo(map);
            circleMarker.bringToFront();
            return circleMarker;
          } else if (i === n - 1) {
            return L.marker(waypoint.latLng, { icon: defaultIcon });
          }
          return null;
        },
        lineOptions: {
          styles: [{ color: "blue", weight: 4 }],
        },
        show: false,
      }).addTo(map);
    }

    return () => {
      if (routingControl) {
        map.removeControl(routingControl);
      }
    };
  }, [map, routeCheckbox, defaultIcon]);

  return null;
};

export const CustomMap = () => {
  const [routeCheckbox, setRouteCheckbox] = useState(false);
  const [depotCheckbox, setDepotCheckbox] = useState(false);
  const [chargerCheckbox, setChargerCheckbox] = useState(false);

  const handleRouteCheckbox = (e) => {
    setRouteCheckbox(e.target.checked);
  };

  const handleDepotCheckbox = (e) => {
    setDepotCheckbox(e.target.checked);
  };

  const handleChargerCheckbox = (e) => {
    setChargerCheckbox(e.target.checked);
  };

  return (
    <div className="map-container">
      <MapContainer
        center={[33.9, -117.5]}
        zoom={10}
        scrollWheelZoom={false}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <RoutingControl routeCheckbox={routeCheckbox} />
      </MapContainer>
      <div className="map-overlay">
        <h3>Include</h3>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={routeCheckbox}
            onChange={handleRouteCheckbox}
          />
          <span className="map-checkbox-description">Routes</span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={depotCheckbox}
            onChange={handleDepotCheckbox}
          />
          <span className="map-checkbox-description">Depots</span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={chargerCheckbox}
            onChange={handleChargerCheckbox}
          />
          <span className="map-checkbox-description">Charging Stations</span>
        </label>
      </div>
    </div>
  );
};
