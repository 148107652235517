import "./App.css";
import ProCalculator from "./pages/pro_calculator/ProCalculator.js";
import HomePage from "./pages/HomePage.js";
import SimpleCalculator from "./pages/SimpleCalculator.js";
import SmoothScroll from "smooth-scroll";
import CalculatorHomePage from "./pages/CalculatorHomePage.js";
import LoginLanding from "./pages/LoginLandingPage.jsx";
import CreateAccount from "./pages/CreateAccount.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import UnderConstruction from "./pages/UnderConstruction.js";
import Profile from "./pages/pro_calculator/profile.js";
import Fleet from "./pages/pro_calculator/fleet.js";
import Depot from "./pages/pro_calculator/depot.js";
import ChargingStations from "./pages/pro_calculator/chargingstations.js";

import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { initGA, PageTracker, debugLog, GADebugger } from "./utils/analytics";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  useEffect(() => {
    initGA();
    if (process.env.NODE_ENV === "development") {
      debugLog("GA4 Initialized in debug mode");
    }
  }, []);

  return (
    <Router>
      <PageTracker />
      <GADebugger />
      <div>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/calculator" element={<CalculatorHomePage />} />
          <Route path="/basic" element={<SimpleCalculator />} />
          <Route path="/SimpleCalculator" element={<SimpleCalculator />} />
          <Route path="/pro" element={<ProCalculator />} />
          <Route path="/login" element={<LoginLanding />} />
          <Route path="/create" element={<CreateAccount />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/construction" element={<UnderConstruction />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/fleet" element={<Fleet />} />
          <Route path="/depot" element={<Depot />} />
          <Route path="/chargers" element={<ChargingStations />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
