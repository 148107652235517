import React, { useState, useEffect } from "react";
import "./DepotTable.css";
import axios from "axios";

export const DepotTable = () => {
  const [tableData, setTableData] = useState(null);
  const [selectedDepots, setSelectedDepots] = useState({});

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getdepot/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setTableData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  const handleCheckboxChange = (depotId) => {
    setSelectedDepots((prev) => ({
      ...prev,
      [depotId]: !prev[depotId],
    }));
  };

  if (!tableData) {
    return <p>Loading ...</p>;
  }

  return (
    <div className="table-container">
      <div className="table-title">
        <h3 className="depot-header">Your Depots</h3>
      </div>
      <table className="table">
        <thead>
          <tr className="table-header">
            <th></th>
            <th>ID</th>
            <th>Depot name</th>
            <th>Address</th>
            <th>Area</th>
            <th>Utility</th>
            <th>Power capacity</th>
            <th>PROPERTIES</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((depot, index) => (
            <tr className="table-content" key={`${depot.name}-${index}`}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedDepots[depot.id] || false}
                  onChange={() => handleCheckboxChange(depot.id)}
                  className="depot-checkbox"
                />
              </td>
              <td>{index + 1}</td>
              <td>{depot.name}</td>
              <td>{depot.address}</td>
              <td>{depot.area}</td>
              <td>{depot.utility}</td>
              <td>{depot.power_capacity}</td>
              <td>
                <button className="edit-button">Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
