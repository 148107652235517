import React from "react";
import { MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import "./SidebarItem.css";

export const SidebarItem = ({ collapsed, icon, text, link }) => {
  const location = useLocation();
  const isActive = location.pathname === link;

  return (
    <MenuItem
      component={<Link to={link} />}
      className={`menu-item ${isActive ? "menu-item-active" : ""}`}
    >
      <div className="menu-item-content">
        {icon}
        {!collapsed && <span>{text}</span>}
      </div>
    </MenuItem>
  );
};

// export const SidebarItem = ({ collapsed, icon, text, link }) => {
//   return (
//     <MenuItem component={<Link to={link}></Link>}>
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           gap: "10px",
//           marginLeft: "10px",
//         }}
//       >
//         {icon}
//         {!collapsed && <span>{text}</span>}
//       </div>
//     </MenuItem>
//   );
// };
