import "../../App.css";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
Chart.register(...registerables);

function ProCalculator() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [fleetNames, setFleetNames] = useState([]);
  const [currentFleet, setCurrentFleet] = useState(null);
    
    const [params, setParams] = useState(null);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const authToken = localStorage.getItem("authToken");

  const navigate = useNavigate();

  useEffect(() => {
    console.log("authtoken", authToken);
    axios
      .get("http://localhost:8000/api/user/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  console.log(authenticated, userData, authToken);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/getallfleet/", {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setFleetNames(response.data);
        if (response.data.length > 0) {
          setCurrentFleet(response.data[0].name);
        }
      })
      .catch((error) => {
        console.log("error fleet", error);
      });
  }, [authToken]);

  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const optimize = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/optimize/",
        { fleet_name: currentFleet },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      console.log("response", response);
    } catch (error) {
      console.log("error", error);
      return;
    }
  };
   

    const testOptimize = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/api/procalculator/', {
                headers: {
                    Authorization: `Token ${authToken}`,
                    'Content-Type': 'application/json' 
                }
            });
            console.log('response', response);
        } catch (error) {
            console.log('error', error);
            return;
        }
    }

  const handleFleetChange = (e) => {
    setCurrentFleet(e.target.value);
  };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="pro-calculator-content">
        <div className="dashboard-container">
          <h1>Dashboard</h1>
          <div className="form-container">
            <div className="dropdown-container">
              <label htmlFor="fleet-select" className="fleet-header">
                {" "}
                Your fleets:{" "}
              </label>
              <select
                id="fleet-select"
                value={currentFleet}
                onChange={handleFleetChange}
              >
                {fleetNames.map((fleet) => (
                  <option key={fleet.name} value={fleet.name}>
                    {fleet.name}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn btn-default" onClick={optimize}>
              Run optimization
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
    


export default ProCalculator;
