import React from "react";
import { Link } from "react-router-dom";

export const HomeButton = () => {
    return (
        <nav id="home-button">
            <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Link to="/" className="logo">
                    <img 
                        src="../img/logo.png" 
                        alt="AmpTrans logo" 
                        className="img-responsive center-block"
                        style={{ width: '30%', maxWidth: '600px', height: 'auto' }}
                    />
                </Link>
            </div>
        </nav>
    )
}