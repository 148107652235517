import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Configure GA4 with debug mode or production mode
export const initGA = () => {
  ReactGA.initialize('G-DGQ14FN3XS', {
    // debug: process.env.NODE_ENV === 'development',
    // testMode: process.env.NODE_ENV === 'development',
    debug: false,
    testMode: false,
  });
};

// Debug logger function
export const debugLog = (message, data) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`🔍 ${message}`, data);
  }
};

// Separate the debugger into its own component with debug-only rendering
export const GADebugger = () => {
    const [events, setEvents] = useState([]);
  
    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        const originalSend = ReactGA.send;
        ReactGA.send = (...args) => {
          setEvents(prev => [...prev, {
            timestamp: new Date().toISOString(),
            data: args[0]
          }]);
          originalSend.apply(ReactGA, args);
        };
  
        return () => {
          ReactGA.send = originalSend;
        };
      }
    }, []);
  
    // Only render in development mode
    if (process.env.NODE_ENV !== 'development') {
      return null;
    }
  
    return (
      <div style={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        background: '#f0f0f0',
        padding: 15,
        borderRadius: 5,
        maxHeight: 300,
        overflowY: 'auto',
        zIndex: 9999,
        fontSize: 12
      }}>
        <h4>GA4 Debug Events</h4>
        {events.map((event, i) => (
          <div key={i} style={{ marginBottom: 10 }}>
            <div>{event.timestamp}</div>
            <pre>{JSON.stringify(event.data, null, 2)}</pre>
          </div>
        ))}
      </div>
    );
  };
  
// Enhanced page view tracking with debug logs
export const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title
    });

    debugLog('GA4 Pageview:', {
      page: location.pathname,
      title: document.title,
      timestamp: new Date().toISOString()
    });
  }, [location]);

  return null;
};
